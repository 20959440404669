


export default [
  {
    title: 'Dashboard',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Invoices',
    route: 'invoices',
    icon: 'FileTextIcon',
  
  },
  {
    title: 'Documents',
    route: 'documents',
    icon: 'FileIcon',
  },
]
